import {
  Badge,
  baseFormattedDate,
  baseFormattedDuration,
  Form,
  formatAmount,
  notifService,
  requestGQL,
  Select,
  useTranslation,
} from '@gimlite/watermelon';
import { Button } from '@gimlite/watermelon/components/button/button.component';
import { DatePicker } from '@gimlite/watermelon/components/datepicker/datepicker.component';
import { FilterExpanded } from '@gimlite/watermelon/components/filter/filter.component';
import { Input } from '@gimlite/watermelon/components/input/input.component';
import { Page } from '@gimlite/watermelon/components/page/page.component';
import { Table } from '@gimlite/watermelon/components/table/table.component';
import { Widget } from '@gimlite/watermelon/components/widget/widget.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { useMyUrl } from '@gimlite/watermelon/hook/useMyUrl.hook';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { clientsGql } from '../../clients/gql/clients.gql';
import { ClientsResponse } from '../gql/clients.gql';
import {
  csvParkingRightsGql,
  CSVParkingRightsResponse,
} from '../gql/csvParkingRights.gql';
import {
  parkingRightsGql,
  ParkingRightsResponse,
} from '../gql/parkingRights.gql';

export const ParkingRightsList = () => {
  const navigate = useNavigate();
  const { getParamsUrl, setParamsUrl, clearParamsUrl } = useMyUrl();
  const [parkingRights, setParkingRights] = useState<any>();
  const [clients, setClients] = useState<any>();
  const { lang } = useTranslation();

  useEffect(() => {
    (async () => {
      try {
        const response = await requestGQL<any, ParkingRightsResponse, any>({
          params: {
            limit: 20,
            ...getParamsUrl,
          },
          gql: parkingRightsGql,
          render: ({ list, paging }) => ({
            list: list.map(
              ({
                currency,
                vehicle,
                site,
                error,
                endDate,
                startDate,
                durationDetails,
                paymentRecordsEntity,
                siteId,
                ...rest
              }) => {
                let payingDuration: number = 0;
                let totalDuration: number = 0;

                durationDetails
                  ?.filter(({ type }) => type === 'Paying')
                  .forEach(({ duration }) => {
                    payingDuration += duration || 0;
                  });

                durationDetails?.forEach(({ duration }) => {
                  totalDuration += duration || 0;
                });

                const paymentRecordSort = Array.isArray(paymentRecordsEntity)
                  ? paymentRecordsEntity?.sort(
                      (b: any, a: any) =>
                        (a.paymentDate
                          ? new Date(a.paymentDate).getTime()
                          : 0) -
                        (b.paymentDate ? new Date(b.paymentDate).getTime() : 0),
                    )
                  : null;

                return {
                  ...rest,
                  address: !site ? 'web' : site?.address1 || '',
                  siteName: site?.name,
                  totalDuration: totalDuration,
                  paymentType: paymentRecordSort
                    ? paymentRecordSort[0]?.type
                    : '',
                  errorCode: error?.code || '',
                  errorMessage: error?.message || '',
                  plate: vehicle.lpn,
                  endDate,
                  startDate,
                  currency,
                  payingDuration,
                  tarifName: 'BASE',
                  salesChannel: siteId ? 'HD' : 'MOBILE',
                  lpn: vehicle?.lpn,
                };
              },
            ),
            paging,
          }),
        });

        setParkingRights(() => response);
      } catch (err) {
        console.log({ err });
      } finally {
        console.log('loading finish');
      }
    })();
  }, [getParamsUrl]);

  useEffect(() => {
    (async () => {
      try {
        const response = await requestGQL<any, ClientsResponse>({
          params: {
            limit: 300,
          },
          gql: clientsGql,
        });

        setClients(() => response);
      } catch (err) {
        console.log({ err });
      } finally {
        console.log('loading finish');
      }
    })();
  }, []);

  const exportCSV = useCallback(
    async ({ clientId }: { clientId?: string | null }) => {
      try {
        const response = await requestGQL<any, CSVParkingRightsResponse>({
          params: { clientId },
          gql: csvParkingRightsGql,
        });

        if (response.downloadURL) {
          window.open(response.downloadURL, '_blank');
          notifService.send('NOTIF', {
            content: 'The CSV file is being downloaded',
            mode: 'success',
          });
        } else {
          notifService.send('NOTIF', {
            content: 'Your CSV export failed',
            mode: 'error',
          });
        }
      } catch (err) {
        console.log({ err });
      } finally {
        console.log('loading finish');
      }
    },
    [],
  );

  return (
    <Page>
      <Widget config={{ title: 'Liste des droits de stationnement' }}>
        <Zone
          config={{
            zones: [['filter'], ['table']],
            rows: ['min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'filter' }}>
            <FilterExpanded
              data={{
                value: {
                  clientId: getParamsUrl?.clientId,
                  plate: getParamsUrl?.plate,
                  beginEnd: getParamsUrl?.beginEnd,
                },
              }}
              handleEvent={{
                submit: (data: any) => {
                  setParamsUrl({ ...getParamsUrl, ...data, page: 1 });
                },
              }}
            >
              <FilterExpanded.Fields>
                <Form.Item
                  config={{
                    name: 'clientId',
                    label: 'Clients',
                  }}
                >
                  <Select
                    data={{
                      items: clients
                        ? clients.list.map(({ slug, _id }: any) => ({
                            label: slug,
                            value: _id,
                          }))
                        : [],
                    }}
                  />
                </Form.Item>
                <Form.Item
                  config={{
                    name: 'plate',
                    label: 'Immat.',
                  }}
                >
                  <Input data={{ defaultValue: getParamsUrl?.plate }} />
                </Form.Item>
                <Form.Item
                  config={{
                    name: 'beginEnd',
                    label: 'Début du stationnement',
                  }}
                >
                  <DatePicker
                    config={{
                      mode: 'range',
                      format: 'datetime',
                    }}
                    data={{ defaultValue: getParamsUrl?.beginEnd }}
                  />
                </Form.Item>
              </FilterExpanded.Fields>
              <FilterExpanded.Actions>
                <Button
                  config={{ text: 'Rechercher', type: { value: 'submit' } }}
                />
                <Button
                  handleEvent={{
                    click: () => clearParamsUrl(),
                  }}
                  config={{
                    text: 'Nettoyer la recherche',
                    disabled: !(
                      getParamsUrl && Object.values(getParamsUrl).length > 0
                    ),
                  }}
                />
                <Button
                  handleEvent={{
                    click: () => {
                      exportCSV({ clientId: getParamsUrl?.clientId });
                    },
                  }}
                  config={{
                    text: 'CSV',
                    icon: 'faFileDownloadSolid',
                  }}
                />
              </FilterExpanded.Actions>
            </FilterExpanded>
          </Zone.Area>
          <Zone.Area config={{ area: 'table' }}>
            <Table<{
              _id: string;
              state: string;
              address: string;
              siteName?: string | null;
              plate: string;
              salesChannel: string;
              amount: number;
              startDate: string;
              endDate: string;
              paymentType: string;
              totalDuration: number;
              ups: string;
              consumer: string;
              currency: string;
              errorCode: string | null;
              errorMessage: string | null;
              payingDuration: number;
              internalId: string | null;
              tarifName: string;
              lpn: string;
            }>
              handleEvent={{
                paging: (value: any) => {
                  setParamsUrl({ ...getParamsUrl, ...value });
                },
                read: (value: any) => {
                  navigate(`/onstreet/parking-rights/${value._id}`);
                },
              }}
              data={parkingRights as any}
              config={{
                columns: [
                  {
                    title: 'Etat',
                    key: 'state',
                    sort: {
                      sorter: (a, b) => {
                        const sortRef = ['ERROR', 'DONE'];

                        return (
                          sortRef.indexOf(a.state) - sortRef.indexOf(b.state)
                        );
                      },
                    },
                    render: (value) => {
                      return value.state !== 'DONE' ? (
                        <Badge config={{ color: 'error', text: value.state }} />
                      ) : (
                        <Badge
                          config={{ color: 'success', text: value.state }}
                        />
                      );
                    },
                  },
                  {
                    title: 'Automate',
                    key: 'siteName',
                    sort: {
                      sorter: (a, b) =>
                        `${a.siteName}`.toLowerCase() >
                        `${b.siteName}`.toLowerCase()
                          ? -1
                          : 1,
                    },
                  },
                  {
                    title: 'Adresse',
                    key: 'address',
                    sort: {
                      sorter: (a, b) =>
                        `${a.address}`.toLowerCase() >
                        `${b.address}`.toLowerCase()
                          ? -1
                          : 1,
                    },
                  },
                  {
                    title: 'Immat.',
                    key: 'lpn',
                    sort: {
                      sorter: (a, b) =>
                        `${a.lpn}`.toLowerCase() > `${b.lpn}`.toLowerCase()
                          ? -1
                          : 1,
                    },
                  },
                  {
                    title: 'Nom du tarif',
                    key: 'tarifName',
                    sort: {
                      sorter: (a, b) =>
                        `${a.tarifName}`.toLowerCase() >
                        `${b.tarifName}`.toLowerCase()
                          ? -1
                          : 1,
                    },
                  },
                  {
                    title: 'Montant total',
                    key: 'amount',
                    sort: {
                      sorter: (a, b) => b.amount - a.amount,
                    },
                    render: (value) =>
                      `${formatAmount({ amount: value.amount, currency: null, lang })}`,
                  },
                  {
                    title: 'Devise',
                    key: 'currency',
                    sort: {
                      sorter: (a, b) =>
                        `${a.currency}`.toLowerCase() >
                        `${b.currency}`.toLowerCase()
                          ? -1
                          : 1,
                    },
                  },
                  {
                    key: 'paymentType',
                    title: 'Moyen de paiement',
                    render: (value) =>
                      value.paymentType === 'EFT' ? 'CB' : value.paymentType,
                    sort: {
                      sorter: (a, b) =>
                        `${a.paymentType}`.toLowerCase() >
                        `${b.paymentType}`.toLowerCase()
                          ? -1
                          : 1,
                    },
                  },
                  {
                    key: 'salesChannel',
                    title: 'Canal de vente',
                    sort: {
                      sorter: (a, b) =>
                        `${a.salesChannel}`.toLowerCase() >
                        `${b.salesChannel}`.toLowerCase()
                          ? -1
                          : 1,
                    },
                  },
                  {
                    key: 'startDate',
                    title: 'Début de stationnement',
                    sort: {
                      sorter: (a, b) =>
                        (b.startDate ? new Date(b.startDate).getTime() : 0) -
                        (a.startDate ? new Date(a.startDate).getTime() : 0),
                      defaultSortOrder: 'ascend',
                    },
                    render: (value) =>
                      value.startDate
                        ? baseFormattedDate({ date: value.startDate, lang })
                        : 'Non définie',
                  },
                  {
                    key: 'endDate',
                    title: 'Fin de stationnement',
                    sort: {
                      sorter: (a, b) =>
                        (b.endDate ? new Date(b.endDate).getTime() : 0) -
                        (a.endDate ? new Date(a.endDate).getTime() : 0),
                    },
                    render: (value) =>
                      value.endDate
                        ? baseFormattedDate({ date: value.endDate, lang })
                        : 'Non définie',
                  },
                  {
                    title: 'Durée payante',
                    key: 'payingDuration',
                    sort: {
                      sorter: (a, b) => a.payingDuration - b.payingDuration,
                    },
                    render: (value) =>
                      value.payingDuration
                        ? baseFormattedDuration({
                            minutes: value.payingDuration / 60,
                          })
                        : null,
                  },
                  {
                    title: 'Durée du stationnement',
                    key: 'totalDuration',
                    sort: {
                      sorter: (a, b) => a.totalDuration - b.totalDuration,
                    },
                    render: (value) =>
                      value.totalDuration
                        ? baseFormattedDuration({
                            minutes: value.totalDuration / 60,
                          })
                        : null,
                  },
                  {
                    title: 'Réference de transaction',
                    key: 'internalId',
                    sort: {
                      sorter: (a, b) =>
                        `${a.internalId}`.toLowerCase() >
                        `${b.internalId}`.toLowerCase()
                          ? -1
                          : 1,
                    },
                    render: (value) => value.internalId || null,
                  },
                ],
                actions: {
                  read: true,
                },
              }}
            />
          </Zone.Area>
        </Zone>
      </Widget>
    </Page>
  );
};
