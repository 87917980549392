import { Link } from '@gimlite/router';
import { Description } from '@gimlite/watermelon/components/description/description.component';
import { DateTime } from 'luxon';

export const readFinanceRender = ({
  collects,
  fragments,
  safeInfos,
  ...rest
}) => {
  const collectsFormatted = collects?.list.map(
    ({
      logDate,
      localAmount,
      coins,
      cardUID,
      fragmentRef,
      currency = 'CHF',
      ...rest
    }) => {
      return {
        ...rest,
        cardUID,
        fragmentRef: (
          <Link to={`/adm/fragmentsByRef/${fragmentRef}`}>
            {fragmentRef.split(':')?.[1]}
          </Link>
        ),
        localAmount: new Intl.NumberFormat(currency, {
          style: 'currency',
          currency: currency,
        }).format(localAmount / 100),
        $expandable: coins ? (
          <Description
            data={Object.entries(coins).map(([key, value]) => {
              return {
                key,
                label: key,
                value,
              };
            })}
          />
        ) : undefined,
        logDate: DateTime.fromISO(logDate).toFormat('dd/MM/yy HH:mm:ss'),
      };
    },
  );

  const safeInfosAmount =
    safeInfos?.graph?.map(
      ({ logDate, currencyAmounts, inhibitedCoins, localAmount }) => ({
        date: new Date(logDate),
        TOK:
          currencyAmounts.find(({ currency }) => currency === 'TOK')
            ?.localAmount || 0,
        CHF:
          currencyAmounts.find(({ currency }) => currency === 'CHF')
            ?.localAmount || 0,
        EUR:
          currencyAmounts.find(({ currency }) => currency === 'EUR')
            ?.localAmount || 0,
        total: localAmount,
      }),
    ) || [];

  const safeInfosCoins = safeInfos?.graph.map(
    ({ logDate, rejectedCoins, inhibitedCoins }) => ({
      date: new Date(logDate),
      rejected: rejectedCoins,
      inhibited: inhibitedCoins,
    }),
  );

  const curencies = new Set(safeInfosAmount.map(({ group }) => group));

  return {
    collects: { paging: collects.paging, list: collectsFormatted },
    safeInfos: {
      ...safeInfos,
      amounts: safeInfosAmount,
      coins: safeInfosCoins,
      curencies: [...curencies].reduce((a, v) => ({ ...a, [v]: v }), {}),
      curenciesFields: [...curencies],
    },
    ...rest,
  };
};
