import jsPDF, { CellConfig } from 'jspdf';

const colTopLeft = (
  col: number,
  cols: number,
  margin: number = 20,
  gutter: number = 0,
  pageWidth: number = 210,
) => {
  const totalMargin = 2 * margin;

  return (
    margin +
    col *
      ((pageWidth - totalMargin) / cols + ((0 === col ? 0 : 1) * gutter) / 2)
  );
};

const labelledValue = (
  doc: jsPDF,
  x: number,
  y: number,
  label: string,
  value: string,
) => {
  doc
    .setFontSize(16)
    .setFont('helvetica', 'bold')
    .text(label, x, y)
    .setFont('helvetica', 'normal')
    .text(value, x, y + 8);
};

export const exportCollectionPdf = (collect: any, site: any) => {
  const { logDate, localAmount, cardUID, currencyAmounts, $expandable } =
    collect;
  const { name, address1, address2, zipcode, city, country } = site;

  const coinData = $expandable ? $expandable.props.data : undefined;

  const doc = new jsPDF();
  let docY = 25;

  labelledValue(doc, colTopLeft(0, 2), docY, 'Site', name ?? '(pas renseigné)');
  labelledValue(doc, colTopLeft(1, 2), docY, 'Date de collecte', logDate);
  docY += 18;
  labelledValue(
    doc,
    colTopLeft(0, 2),
    docY,
    'Card Id',
    cardUID ?? '(pas renseigné)',
  );
  docY += 18;
  labelledValue(
    doc,
    20,
    docY,
    'Adresse',
    `${address1}${
      address2 ? '\n' + address2 : ''
    }\n${zipcode} ${city}, ${country}`,
  );

  docY += address2 ? 22 : 16;

  const docYCopy = docY;
  if (currencyAmounts) {
    const toObject = currencyAmounts.reduce(
      (acc: Record<string, number>, { currency, foreignAmount }: any) => {
        acc[currency] = foreignAmount;
        return acc;
      },
      {},
    );
    let tableSumPerCurrency = Object.entries(toObject).map(
      ([currency, amount]) => {
        const formattedAmount = ((amount as number) / 100).toFixed(2);

        return {
          currency,
          amount: formattedAmount,
        } as Record<string, string>;
      },
    );

    docY += 10;
    doc.setFontSize(16).setFont('helvetica', 'bold');
    doc.text(`Montant par monnaie`, colTopLeft(0, 2), docY);

    docY += 6;
    doc.table(
      colTopLeft(0, 2, 25, 10),
      docY,
      tableSumPerCurrency,
      ['currency', 'amount'].map((key): CellConfig => {
        return {
          name: key,
          prompt: 'currency' === key ? 'Monnaie' : 'Montant',
          width: 40,
          align: 'currency' === key ? 'left' : 'right',
          padding: 0,
        };
      }),
      { autoSize: false },
    );

    docY += 10 + 11 * (tableSumPerCurrency.length + 1);
  }

  labelledValue(doc, colTopLeft(0, 2), docY, 'Montant total', localAmount);

  docY = docYCopy;
  if (coinData) {
    docY += 10;
    doc.setFontSize(16).setFont('helvetica', 'bold');
    doc.text(`Décompte des pièces`, colTopLeft(1, 2), docY);

    docY += 6;
    doc.table(
      colTopLeft(1, 2, 25, 10),
      docY,
      coinData.map(({ value, ...rest }: { value: number }) => ({
        ...rest,
        value: value.toString(),
      })),
      ['label', 'value'].map((key): CellConfig => {
        return {
          name: key,
          prompt: 'label' === key ? 'Type' : 'Décompte',
          width: 40,
          align: 'label' === key ? 'left' : 'right',
          padding: 0,
        };
      }),
      { autoSize: false },
    );
  }

  doc.save(`export-collection.pdf`);
};
